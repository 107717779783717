import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import color from '../../../constants/colors'

const Container = styled.div`
  margin-top: 40px;
`

const Card = styled.div`
  border: 1px solid ${color.lightSilver};
  color: ${color.heading};
  margin-bottom: 20px;
  padding: 30px 15px;
  position: relative;
`

const CellWrapper = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.8rem;
  justify-content: space-between;

  ${media.lessThan('medium')`
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  `}
`

const HeadCell = styled.div`
  font-weight: bold;
  padding: 20px 15px;
  text-align: center;
  width: 50%;

  p {
    margin: 5px 0;
  }

  ${media.lessThan('medium')`
    width: 100%;
  `}
`

const OriginalPriceCell = styled.div`
  font-size: 2rem;
  padding: 20px 5px;
  position: relative;
  text-align: center;
  width: 50%;
`

const AnnotationContainer = styled.div`
  margin-top: 70px;
`

const Annotation = styled.p`
  bottom: 0;
  left: 30px;
  margin-bottom: 5px;
  text-align: left;

  ${media.lessThan('medium')`
    font-size: 1.4rem;
  `}
`

const PriceTable = () => (
  <Container>
    <Card>
      <CellWrapper isBottomRow>
        <HeadCell>
          <p>
            12回コース
          </p>
          <p>
            （通常価格）
          </p>
        </HeadCell>
        <OriginalPriceCell data-sal>132,000円</OriginalPriceCell>
      </CellWrapper>
    </Card>
    <AnnotationContainer>
      <Annotation>
        ※料金は税込価格です。
      </Annotation>
      <Annotation>
        ※料金にはオリジナルテキストの教材費が含まれています。
      </Annotation>
      <Annotation>
        ※3か月コース（月4回）と4か月コース（月3回）からお選び頂きます。
      </Annotation>
    </AnnotationContainer>
  </Container>
)

export default PriceTable
