import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

const ImageWrapper = styled.div`
  width: 100%;
`

const ImageMeeting = () => {
  let isSp = false
  if (typeof window !== 'undefined' && window.screen.width < 500) {
    isSp = true
  }
  return (
    <ImageWrapper>
      {isSp ? (
        <StaticImage
          src="../../images/meeting-mobile.jpg"
          quality={90}
          placeholder="none"
          layout="fullWidth"
          alt="meeting-in-english"
        />
      ) : (
        <StaticImage
          src="../../images/meeting.jpg"
          quality={90}
          placeholder="none"
          layout="fullWidth"
          alt="meeting-in-english"
        />
      )}
    </ImageWrapper>
  )
}

export default ImageMeeting
