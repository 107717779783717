import React from 'react'
import styled from 'styled-components'
import SectionHeading from '../SectionHeading'
import { Section, InnerSmall } from '../Sections'
import color from '../../constants/colors'

const Container = styled.div`
  position: relative;  // for internal page link
`

// add top as offset for internal page link
const Anchor = styled.a`
  display: hidden;
  position: absolute;
  top: -140px;
`

const InnerStyled = styled(InnerSmall)`
  padding-left: 30px;
  padding-right: 30px; 
  padding-top: 0;
`

const Text = styled.p`
  color: ${color.text};
  text-align: left;
`

const About = () => (
  <Container>
    <Anchor id="about-anchor" />
    <SectionHeading
      id="about"
    >
      Moba Pro IT Englishとは
    </SectionHeading>
    <Section>
      <InnerStyled>
        <Text>
          Moba Pro IT English（モバプロ）は、ITエンジニア専用のオンライン英会話レッスンプログラムです。
        </Text>
        <Text>
          Moba Pro IT Englishを提供する株式会社もばらぶはウェブシステムの開発やアプリ開発、機械学習に関わるシステムの構築など様々なプロジェクトを手掛けるIT企業です。
          2013年の創業当初からリモートワークを中心に業務を進めてきたこともあり、
          積極的に海外のエンジニアを開発体制に加えてプロジェクトを進めてきたことで、
          業務における英語力の必要性を感じていました。
        </Text>
        <Text>そこで、英語教室の運営事業を開始するとともに、社内教育の一環としての英語教育プログラムの開発プロジェクトを立ち上げました。</Text>
        <Text>
          構想から約３年。試行錯誤を繰り返し、十分な社内検証を経てようやく実務レベルで使える英語教育プログラムが完成致しました。
        </Text>
        <Text>
          あまたの英語教育プログラムが存在する中、真にITエンジニアに特化されたものは未だ少数です。
          このため、種々の英会話レッスンを受講してみるも今一つ業務との親和性に欠け、物足りなさを感じたご経験はありませんか？
          このような不満にこたえるべく、この度「Moba Pro IT English」を広く提供させていただく運びとなりました。
        </Text>
        <Text>
          「Moba Pro IT English」はITエンジニアが現場で使える英語表現を厳選したオリジナル教材を用いて、
          経験豊富な日本人講師が「会話文の作り方を教授する」、会話にフォーカスした内容になっています。
        </Text>
        <Text>
          英語を身につけることで仕事の幅が広がることは間違いありません。
          どうですか？「Moba Pro IT English」一緒に始めてみませんか？
        </Text>
      </InnerStyled>
    </Section>
  </Container>
)

export default About
