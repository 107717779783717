import React from 'react'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import HeroSection from '../components/pageSections/Hero'
import CatchphraseSection from '../components/pageSections/Catchphrase'
import ImageMeetingSection from '../components/pageSections/ImageMeeting'
import AboutSection from '../components/pageSections/About'
import StrengthsSection from '../components/pageSections/Strengths'
import UsersVoiceSection from '../components/pageSections/UsersVoice'
import PriceSection from '../components/pageSections/Price/index'
import TrialSection from '../components/pageSections/Trial/index'

const IndexPage = ({ location: { pathname } }) => (
  <Layout isTopPage>
    <SEO
      title="Moba Pro IT English | ITエンジニア専用のオンライン英会話スクール・モバプロ"
      description="Moba Pro IT English（モバプロ）は、ITエンジニア専用のオンライン英会話スクールです。日本人講師が「会話文の作り方」にフォーカスして教えることにより、英会話の基礎力をつけることができます。"
      pathName={pathname}
    />
    <HeroSection />
    <CatchphraseSection />
    <ImageMeetingSection />
    <AboutSection />
    <StrengthsSection />
    <UsersVoiceSection />
    <PriceSection />
    <TrialSection />
  </Layout>
)

IndexPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

IndexPage.defaultProps = {
  location: null,
}

export default IndexPage
