import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import {
  Section,
  InnerMedium,
} from '../../Sections'
import SectionHeading from '../../SectionHeading'
import PriceTable from './PriceTable'

const InnerMain = styled(InnerMedium)`
  max-width: 720px;
  padding: 0 0 80px;

  ${media.lessThan('medium')`
    padding: 30px;
  `}
`

const MainContainer = styled.div`
  border: 1px solid #f1f1f1;
  box-shadow: 0 0 14px rgb(0 0 0 / 7%);
  border-radius: 8px;
  background-color: white;
  margin-top: 80px;
  padding: 40px 40px 80px;

  ${media.lessThan('medium')`
    margin-top: 0;
    padding: 20px 20px 20px;
  `}
`

const Price = () => (
  <>
    <SectionHeading bgColor="dark">
      料金表
    </SectionHeading>
    <Section>
      <InnerMain>
        <MainContainer>
          <PriceTable />
        </MainContainer>
      </InnerMain>
    </Section>
  </>
)

export default Price
