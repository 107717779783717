import React from 'react'
import styled from 'styled-components'
import Voice from '../VoiceItem'
import {
  Section, InnerMedium,
} from '../Sections'
import SectionHeading from '../SectionHeading'
import color from '../../constants/colors'

const Container = styled.div`
  position: relative; // for internal page link
`

// add top as offset for internal page link
const Anchor = styled.a`
  display: hidden;
  position: absolute;
  top: -140px;
`

const InnerStyled = styled(InnerMedium)`
  padding-top: 0;
`

const ButtonStyled = styled.a`
  transition: all .3s;
  background-color: ${color.main};
  border: none;
  cursor: pointer;
  color: white;
  display: block;
  font-size: clamp(1.5rem, 1.371rem + 0.57vw, 1.8rem);
  font-weight: bold;
  height: 45px;
  line-height: 45px;
  margin-top: 20px;
  max-width: 400px;
  margin: 0 auto;
  padding: 0 30px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: ${color.mainHighlighted};
  }
`

const UsersVoice = () => (
  <Container>
    <Anchor id="voice-anchor" />
    <SectionHeading
      id="voice"
    >
      受講者の声
    </SectionHeading>
    <Section>
      <InnerStyled>
        <Voice
          imageSrc="/image/voice/student-man.svg"
          userInfo="３０代・男性"
        >
          <p>
            最初は英語を話すことに抵抗がありましたが、レッスンを繰り返すうちに抵抗がなくなり、スピーキングに関して自信が持てるようになりました。
          </p>
          <p>
            以前は英語の文章が目に入ると嫌になったり、
            すぐに翻訳機に頼ってしまっていましたが、
            今は「自分でわかるところまで読んでみる」
            「知らない単語を知るのが楽しい」という、
            気持ちの変化がありました。
          </p>
          <p>
            仕事の都合上、
            IT業界(特にプログラム関連)の英文を読むことが多く、
            このレッスンではそういった英文に出てくるマニアックな単語もカバーしてくれるので、より理解しやすくなりました。
          </p>
        </Voice>
        <Voice
          imageSrc="/image/voice/student-woman.svg"
          userInfo="３０代・女性"
          imageOnRight
        >
          <p>
            「進捗報告」「テストについて」等、実際の業務を想定した内容になっているので、実践的な内容だったのが良かったです。
          </p>
          <p>時間の面で融通が利くのもとてもありがたく（私は平日21時～受講していました）、子育て、仕事をしていても問題なく受講を続けることができます。</p>
          <p>また、レッスンの内容だけでなく、自分の近況等を英語で先生に話をしたりすることができる点も良かったです。</p>
          <p>先生のおかげで雰囲気良くレッスンを受講できる点も良い点でした。</p>
        </Voice>
        <Voice
          imageSrc="/image/voice/student-glasses.svg"
          userInfo="４０代・男性"
        >
          <p>
            レッスンでは表現の誤りや不透明性を指摘してもらい、その点に気をつけることで実際の英語でのやりとりが改善できているように感じています。
          </p>
          <p>テキストも使いやすく、テキストで出てきた業務上のシーンで使える表現を実際に業務で英語を使う際に取り入れることもできています。</p>
          <p>海外の質問系のサイトなどで英語のやり取りを読んでいるとき、テキストで覚えた単語がそのまま出ていて翻訳せずに読めることが増えてきました。</p>
          <p>また、海外の記事やちょっとした英文でのやり取りをいちいち翻訳せずに読めたときにも英語の上達を実感しました。</p>
        </Voice>
        <Voice
          imageSrc="/image/voice/student-glasses.svg"
          userInfo="４０代・男性"
          imageOnRight
        >
          <p>
            知らない単語や専門用語が多く、学習するたびに新しい発見がありました。
          </p>
          <p>日本人講師だったので質問もしやすく、文法やニュアンスに関する質問にも丁寧に答えてもらえ、理解を深めることができました。</p>
          <p>中でも、特に印象的だったのは、実務に即した教材です。これまでの学習では、ここまで実務に即した教材に出会うことはありませんでした。</p>
          <p>実際の業務で直面するような状況を想定した教材は、非常に魅力的で楽しめました。</p>
          <p>エンジニアとしてのさらなるキャリアアップを考えている人には、ぜひモバプロをおすすめしたいです。</p>
          <ButtonStyled href="https://blog.mobalab.net/2024/04/05/%e3%83%a2%e3%83%90%e3%83%97%e3%83%ad%e5%8f%97%e8%ac%9b%e7%94%9f%e3%81%8c%e8%aa%9e%e3%82%8b%e8%8b%b1%e8%aa%9e%e3%81%a8%e3%82%ad%e3%83%a3%e3%83%aa%e3%82%a2%ef%bc%9a%e3%83%a2%e3%83%90%e3%83%97%e3%83%ad/" target="_blank">
            体験談の詳細はこちらから
          </ButtonStyled>
        </Voice>
      </InnerStyled>
    </Section>
  </Container>
)

export default UsersVoice
