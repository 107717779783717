import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { StaticImage } from 'gatsby-plugin-image'
import NavigationLinks from '../Navigation/NavigationLinks'
import { Section, InnerLarge } from '../Sections'
import color from '../../constants/colors'

const Logo = styled.img`
  margin-left: 50px;
  margin-top: none;
  width: 180px;
  z-index: 20;

  ${media.lessThan('medium')`
    margin-left: auto;
    margin-right: auto;
  `}
`

const SectionTop = styled(Section)`
  height: none;
`

const InnerTop = styled(InnerLarge)`
  padding-top: none;
  display: flex;
  height: 180px;  
  justify-content: space-between;
  padding: 40px 20px;
`

const SectionMain = styled(Section)`
  background-color: ${color.dark};
  height: 500px;
`

const InnerMain = styled(InnerLarge)`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: space-evenly;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 500px;
  width: 50%;

  ${media.lessThan('medium')`
    margin-top: 30px;
    max-width: 500px;
    width: 100%;
  `}
`

const TextMain = styled.h1`
  color: white;
  font-size: 4rem;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 3rem;
    margin: 45px 0 45px;
  `}
`

const WithBorderBottom = styled.span`
  padding-bottom: 4px;
  position: relative;

  &:after {
    background: ${color.main};
    bottom: -6px;
    content: '';
    display: block;
    height: 6px;
    position: absolute;
    transition: width .7s;
    transition-delay: .7s;
    width: 0;
  }

  &.sal-animate::after {
    width: 100%;
  }

  ${media.lessThan('medium')`
    &:after {
      height: 4px;
    }
  `}
`

const Hero = () => (
  <>
    <SectionTop>
      <InnerTop>
        <Logo src="/image/logos/logo-mobapro.svg" alt="" />
        <NavigationLinks isTopPage />
      </InnerTop>
    </SectionTop>
    <SectionMain>
      <InnerMain>
        <ImageWrapper>
          <StaticImage
            alt=""
            src="../../images/image-top.png"
            placeholder="none"
            quality={100}
          />
        </ImageWrapper>
        <TextMain>
          <WithBorderBottom data-sal>ITエンジニア専用</WithBorderBottom>
          の
          <br />
          オンライン英会話
        </TextMain>
      </InnerMain>
    </SectionMain>
  </>
)

export default Hero
