import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media, { defaultBreakpoints } from 'styled-media-query'
import color from '../constants/colors'

const Container = styled.div`
  border-radius: 5px;
  background-color: white;
  display: flex;
  margin-bottom: 30px;
  overflow: hidden;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.lessThan('medium')`
    flex-direction: column-reverse;
  `}
`

const Body = styled.div`
  padding: 25px 0;
  width: 50%;
  text-align: left;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`

const Number = styled.div`
  background-image: ${(props) => (
    `url("/image/strengths/${props.number}.svg")`
  )};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 75px;
  margin-bottom: 35px;
  margin-top: 15px;

  ${media.lessThan('medium')`
    height: 50px;
  `}
`

const BodyTItle = styled.h3`
  color: ${color.heading};
  font-size: 3rem;
  font-weight: lighter;
  line-height: 4rem;
  margin-top: 20px;
  text-align: center;
  white-space: pre-line;

  ${media.lessThan('medium')`
    font-size: 2.6rem;
    line-height: 3.6rem;
  `}
`

const Text = styled.p`
  color: ${color.text};
  padding: 0 45px;

  ${media.lessThan('medium')`
    padding: 0 20px;
  `}
`

const ImageWrapper = styled.div`
  display: flex;
  width: 50%;

  ${media.lessThan('medium')`
    display: none;
  `}
`

const StrengthItem = ({ children, data, number }) => {
  let showMobileImage = false

  if (typeof window !== 'undefined') {
    showMobileImage = window.innerWidth < defaultBreakpoints.medium.split('px')[0]
  }

  const imageOnLeft = number % 2 === 0

  return (
    <Container>
      {imageOnLeft && (
        <ImageWrapper>
          { children }
        </ImageWrapper>
      )}
      <Body>
        <Number
          data-sal="fade"
          data-sal-delay="300"
          data-sal-duration={2000}
          number={number}
        />
        <BodyTItle>
          {data.heading}
        </BodyTItle>
        {showMobileImage && (
          children
        )}
        {data.body.map((paragraph) => (
          <Text key={paragraph.slice(0, 10)}>{paragraph}</Text>
        ))}
      </Body>
      {!imageOnLeft && (
        <ImageWrapper>
          { children }
        </ImageWrapper>
      )}
    </Container>
  )
}

StrengthItem.propTypes = {
  children: PropTypes.node,
  data: PropTypes.shape({
    imageName: PropTypes.string,
    heading: PropTypes.string,
    body: PropTypes.arrayOf(PropTypes.string),
  }),
  number: PropTypes.number,
}

StrengthItem.defaultProps = {
  children: null,
  data: null,
  number: 1,
}

export default StrengthItem
