import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import color from '../constants/colors'

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: ${(props) => (props.imageOnRight ? 'row-reverse' : 'none')};
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.lessThan('medium')`
    flex-direction: column-reverse;
    align-items: center;
  `}
`

const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px 15px;
`

const UserInfo = styled.p`
  text-align: center;
  margin: 0;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background: ${color.main};
    transition: width .7s;
    transition-delay: .5s;
  }

  &.sal-animate::after {
    width: 100%;
  }
`

const PersonImage = styled.img`
  display: block;
  width: 120px;

  ${media.lessThan('medium')`
    margin: 0 auto;
    padding-top: 0;
  `}
`

const Body = styled.div`
  background-color: ${color.silverBg};
  flex: 1;
  margin: ${(props) => (props.imageOnRight ? '0 50px 0 0' : '0 0 0 50px')};
  padding: 30px 40px;
  position: relative;

  ${media.lessThan('medium')`
    margin: 0 0 50px;
  `}

  ::after {
    border-color: transparent ${color.silverBg} transparent transparent;
    border-style: solid;
    border-width: 12px 40px 12px 0;
    content: '';
    height: 0;
    position: absolute;
    left: ${(props) => (props.imageOnRight ? 'none' : '-40px')};;
    top: 80px;
    width: 0;
    z-index: -3;

    right: ${(props) => (props.imageOnRight ? '-40px' : 'none')};
    transform: ${(props) => (props.imageOnRight ? 'scaleX(-1)' : 'none')};
    // fixme: should refactor creating BodyImageOnRight

    ${media.lessThan('medium')`
      bottom: -30px;
      border-width: 12px 40px 12px 0;
      left: calc(50% - 20px);
      top: unset;
      transform: rotate(270deg);
    `}
  }
`

const VoiceItem = ({
  children, imageSrc, userInfo, imageOnRight,
}) => (
  <Container imageOnRight={imageOnRight}>
    <ImageContainer>
      <PersonImage
        src={imageSrc}
        alt=""
      />
      <UserInfo data-sal>{ userInfo }</UserInfo>
    </ImageContainer>
    <Body imageOnRight={imageOnRight}>
      { children }
    </Body>
  </Container>
)

VoiceItem.propTypes = {
  children: PropTypes.node,
  imageSrc: PropTypes.string,
  userInfo: PropTypes.string,
  imageOnRight: PropTypes.bool,
}

VoiceItem.defaultProps = {
  children: null,
  imageSrc: '',
  userInfo: '',
  imageOnRight: false,
}

export default VoiceItem
