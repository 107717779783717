import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { Section, InnerLarge } from '../Sections'
import color from '../../constants/colors'

const InnerStyled = styled(InnerLarge)`
  padding: 160px 40px;
  text-align: center;

  ${media.lessThan('medium')`
    padding: 80px 40px;
  `}
`

const TextMain = styled.h2`
  color: ${color.text};
  display: inline-block;
  font-size: 4rem;
  font-weight: lighter;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 2.6rem;
    line-height: 3.6rem;
  `}
`

const TextSub = styled.p`
  color: ${color.text};
`

const Catchphrase = () => (
  <Section>
    <InnerStyled>
      <TextMain>
        あなたのスキルを
        <br />
        世界に届けよう
      </TextMain>
      <TextSub>独自のカリキュラムであなたの「話す」力が変わります。</TextSub>
    </InnerStyled>
  </Section>
)

export default Catchphrase
