import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import SectionHeading from '../SectionHeading'
import { Section, InnerMedium } from '../Sections'
import color from '../../constants/colors'
import StrengthItem from '../StrengthItem'

const Container = styled.div`
  position: relative; // for internal page link
  background-color: ${color.dark};
`

// add top as offset for internal page link
const Anchor = styled.a`
  display: hidden;
  position: absolute;
  top: -140px;
`

const Strengths = () => (
  <Container>
    <Anchor id="strengths-anchor" />
    <SectionHeading
      id="strengths"
      paddingY={80}
      bgColor="dark"
    >
      モバプロの5つの特徴
    </SectionHeading>
    <Section>
      <InnerMedium paddingTop={0}>
        <StrengthItem
          data={{
            imageName: 'textbook.jpg',
            heading: '現役エンジニア監修の\nオリジナルテキスト',
            body: [
              '使用するオリジナルテキストは「仕様の説明」「進捗の確認」「設計についての議論」など、現役のエンジニアが監修したIT関連の業務に沿ったものとなっています。',
              'テキストの内容が実際にシステム開発で使われるような場面を想定していることで、IT業務に関する実践的な語彙を身につけることができます。',
              'また、提案・質問・依頼など実務でよく使われる表現を系統立てて学ぶことができます。',
            ],
          }}
          number={1}
        >
          <StaticImage
            alt=""
            src="../../images/strengths/textbook.jpg"
            quality={80}
          />
        </StrengthItem>
        <StrengthItem
          data={{
            imageName: 'student.jpg',
            heading: '会話力を伸ばすことに\nフォーカスした授業',
            body: [
              '「パターンプラクティス」と「瞬間英作文」を基本から教えることにより、他のサービスのようなフリートーク中心のレッスンではなく、フリートークの練習の前に習得するべき会話力の基礎を作ることに重点を置いています。',
              'フリートーク中心の授業では学ぶことのできない、英文の構造の理解から英文を作ることの瞬発力を鍛える方法をシステマティックに学び、授業で繰り返し練習することで英会話力の基礎からの底上げのサポートをします。',
              '又、各章の習熟度を確認するため小テスト計３回、最終確認テスト1回の全４回のスピーキングテストが組み込まれている点も他社にはない特徴です。',
              'フリートークの練習も併せて行っていきますので、いきなりのフリートークでのレッスンに不安のある方でも徐々に英会話に慣れていくことができます。',
            ],
          }}
          number={2}
        >
          <StaticImage
            alt=""
            src="../../images/strengths/student.jpg"
            quality={80}
          />
        </StrengthItem>
        <StrengthItem
          data={{
            imageName: 'teacher-1.jpg',
            heading: '日本人講師によるレッスン',
            body: [
              'モバプロの講師は全員が日本人講師です。',
              'それはモバプロでは英語の初心者向けに「会話文の作り方を教授する」ことにフォーカスしていることが理由です。',
              '海外の特にネイティブまたは非ネイティブスピーカーが教える他のサービスでは実現できない、英語をゼロから学んでマスターした経験のある日本人講師が教えることで、語句・品詞チェック・苦手な文法・文型・間違いやすさの傾向など日本人講師しかできない細やかな教授方法で英会話の基礎を教えます。',
            ],
          }}
          number={3}
        >
          <StaticImage
            alt=""
            src="../../images/strengths/teacher-1.jpg"
            quality={80}
          />
        </StrengthItem>
        <StrengthItem
          data={{
            imageName: 'teacher-2.jpg',
            heading: '担任講師制を採用',
            body: [
              'モバプロでは担任講師制を採用しています。一人の講師が全てのレッスンを行うことで、一人ひとりの特徴を講師が把握することができます。',
              '毎回の授業で担当講師が異なる他のサービスとは一線を引き、生徒の英語力を講師が総合的に分析したうえで定期的にレポートを行います。これにより、英語上達において非常に重要な現状の課題を客観的に把握することができます。',
              '担任講師は英会話の習得における疑問点や悩み、また最適な学習方法などを質問できる英語学習コーチの役割を果たします。',
            ],
          }}
          number={4}
        >
          <StaticImage
            alt=""
            src="../../images/strengths/teacher-2.jpg"
            quality={80}
          />
        </StrengthItem>
        <StrengthItem
          data={{
            imageName: 'schedule.jpg',
            heading: '無理のないレッスン回数',
            body: [
              'モバプロのレッスンは月に4回または月に3回となっているので、無理のないペースで英会話力の基礎を身につけることができます。',
              'よくある月に通い放題といった形式をとっていないので、仕事で忙しくてなかなか存分に利用できかねるという方にも最適です。',
              '担任との相談によりご自宅での予習・復習の実行を織り交ぜることで、密度の濃い授業を無理のないレッスン回数で実現できるように構成されています。',
            ],
          }}
          number={5}
        >
          <StaticImage
            alt=""
            src="../../images/strengths/schedule.jpg"
            quality={80}
          />
        </StrengthItem>
      </InnerMedium>
    </Section>
  </Container>
)

export default Strengths
